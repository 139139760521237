import React from 'react'
import Navbar from '../Components/Navbar'
import { useNavigate } from 'react-router-dom';
import Footer from '../Components/Footer'

const OurTeamPage = () => {
    
    const navigate = useNavigate();
    const contact = () => navigate('/contact');
  return (
    <div>
        <Navbar />
        <div className="d-none d-lg-block">
        <div id='header' className="container-fluid pt-5 hero-header mb-5" style={{backgroundColor:"#242424"}}>
                <div className="container pt-5">
                    <div className="row g-5 pt-5">
                        <div className="col-lg-12 align-self-center text-center text-lg-start mb-lg-5"  style={{paddingTop:"50px",paddingBottom:"250px"}}>
                            <div className="btn btn-sm border rounded-pill text-white px-3 mb-3 animated slideInRight">Fusion Force</div>
                            <h1 className="display-4 text-white mb-4 animated slideInRight">OUR <span style={{ color: "#C11E70" }}>TEAM</span></h1>
                            <a onClick={contact} className="btn btn-outline-light py-sm-3 px-sm-5 rounded-pill animated slideInRight">Contact Us</a>
                        </div>
                    </div>
                </div>
            </div>
            </div>

            <div className="d-lg-none">
        <div id='header' className="container-fluid pt-5 bg-dark mb-5">
                <div className="container pt-5">
                    <div className="row g-5 pt-5">
                        <div className="col-lg-6 align-self-center text-center text-lg-start mb-lg-5">
                            <div className="btn btn-sm border rounded-pill text-white px-3 mb-3 animated slideInRight">Fusion Force</div>
                            <h1 className="display-4 text-white mb-4 animated slideInRight">OUR <span style={{ color: "#C11E70" }}>TEAM</span></h1>
                            <a onClick={contact} className="btn btn-outline-light py-sm-3 px-sm-5 rounded-pill animated slideInRight">Contact Us</a>
                        </div>
                        <div className="col-lg-6 align-self-end text-center text-lg-end">
                    <img className="img-fluid" src="img/fusionLogo.png" alt=""/>
                </div>
                    </div>
                </div>
            </div>
            </div>

    
         <div id='team' className="container-fluid bg-light py-5">
        <div className="container py-5">
            <div className="row g-5 align-items-center">
                <div className="col-lg-5 wow fadeIn" data-wow-delay="0.1s">
                    <div className="btn btn-sm border rounded-pill text-orange px-3 mb-3">Our Team</div>
                    <h1 className="mb-4">Meet Our Experienced Team Members</h1>
                    <p className="mb-4">Team Fusion Force: We Code Your Vision into Reality</p>
                </div>
                <div className="col-lg-7">
                    <div className="row g-4">
                        <div className="col-md-6">
                            <div className="row g-4">
                                <div className="col-12 wow fadeIn" data-wow-delay="0.1s">
                                    <div className="team-item bg-white text-center rounded p-4 pt-0">
                                        <img className="img-fluid rounded-circle p-4" src="img/OsamaAli.jpg" alt=""/>
                                        <h5 className="mb-0">Syed Osama Ali</h5>
                                        <small>Founder & CEO</small>
                                        <div className="d-flex justify-content-center mt-3">
                                        <a className="btn btn-square ln-orange m-1" href="https://www.linkedin.com/in/cvengg/" target='_blank'><i
                                                    className="fab fa-linkedin-in"></i></a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 wow fadeIn" data-wow-delay="0.5s">
                                    <div className="team-item bg-white text-center rounded p-4 pt-0">
                                        <img className="img-fluid rounded-circle p-4" src="img/Faseehullah.jpg" alt=""/>
                                        <h5 className="mb-0">Muhammad Faseeh Ullah</h5>
                                        <small>Director</small>
                                        <div className="d-flex justify-content-center mt-3">
                                        <a className="btn btn-square ln-orange m-1" href="https://www.linkedin.com/in/faseehullah/" target='_blank'><i
                                                    className="fab fa-linkedin-in"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 pt-md-4 mt-5">
                            <div className="row g-4 mt-5">
                                <div className="col-12 wow fadeIn mt-5" data-wow-delay="0.3s">
                                    <div className="team-item bg-white text-center rounded p-4 pt-0">
                                        <img className="img-fluid rounded-circle p-4" src="img/umairsiddiqui.jpg" alt=""/>
                                        <h5 className="mb-0">Umair Siddiqui</h5>
                                        <small>Co Founder</small>
                                        <div className="d-flex justify-content-center mt-3">
                                        <a className="btn btn-square ln-orange m-1" href="https://www.linkedin.com/in/muhammad-umair-siddiqui-bb7108b4/" target='_blank'><i
                                                    className="fab fa-linkedin-in"></i></a>
                                        </div>
                                    </div>
                                </div>  
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <Footer />
    </div>
  )
}

export default OurTeamPage

