import React from 'react'
import { useNavigate } from 'react-router-dom';

const Header = () => {

        const navigate = useNavigate();
      const contact = () => navigate('/contact');
    return (
        <div>
            <div className="d-none d-lg-block postion-absolute">
                <video className='w-100'
                    id='myVideo'
                    playsInline
                    autoPlay
                    muted
                    preload="none"
                    loop>
                    <source src={require('../video/Hexsync website banner.webm')} />
                </video>
            </div>

            <div className="d-lg-none">
            <div id='header' className="container-fluid pt-5 bg-dark mb-5">
        <div className="container pt-5">
            <div className="row g-5 pt-5">
                <div className="col-lg-6 align-self-center text-center text-lg-start mb-lg-5">
                    <div className="btn btn-sm border rounded-pill text-white px-3 mb-3 animated slideInRight">Fusion Force</div>
                    <h1 className="display-4 text-white mb-4 animated slideInRight">FORGING FUTURE IN OUR <span style={{color:"#C11E70"}}>HIVE</span></h1>                    
                    <a onClick={contact} className="btn btn-outline-light py-sm-3 px-sm-5 rounded-pill animated slideInRight">Contact Us</a>
                </div>
                <div className="col-lg-6 align-self-end text-center text-lg-end">
                    <img className="img-fluid" src="img/fusionLogo.png" alt=""/>
                </div>
            </div>
        </div>
    </div>


            </div>


            {/* */}
        </div>
    )
}

export default Header