import React from 'react'
import logo from '../img/logo/Logo For Website White.png'

const Footer = () => {
  return (
    <div>
         <div className="container-fluid bg-dark text-white-50 footer pt-5">
        <div className="container py-5">
            <div className="row g-5">
                <div className="col-md-8 col-lg-6 wow fadeIn" data-wow-delay="0.1s">
                    <a href="#" className="d-inline-block mb-3">
                    <img src={logo} style={{width:"200px", height:"89"}}/>
                    </a>
                    <p className="mb-0 text-justify">Join us on this transformative journey, where technology meets innovation, and together, we can shape a brighter digital future for your business.</p>
                </div>
                <div className="col-md-4 col-lg-6 wow fadeIn " style={{float:"right"}} data-wow-delay="0.3s">
                    <h5 className="text-white mb-4">Get In Touch</h5>
                    <p><i className="text-orange fa fa-map-marker-alt me-3"></i>Office 85, Baynunah Tower 2, Al Hisn, Abu Dhabi</p>
                    <p><i className="text-orange fa fa-phone-alt me-3"></i>+971 559 286 154</p>
                    <p><i className="text-orange fas fa-envelope me-3"></i>info@fusionforcellc.com</p>
                    {/* <p><i className="fa fa-envelope me-3"></i>info@example.com</p> */}
                    <div className="d-flex pt-2">
                    <a className="btn btn-outline-orange-dark btn-square me-3" href="https://www.facebook.com/FusionForce" target='_blank'><i className="fab fa-facebook-f" ></i></a>
                        <a className="btn btn-outline-orange-dark btn-square me-3" href="https://www.instagram.com/FusionForce" target='_blank'><i className="fab fa-instagram"></i></a>
                        <a className="btn btn-outline-orange-dark btn-square me-3" href="https://www.linkedin.com/company/FusionForce" target='_blank'><i className="fab fa-linkedin-in"></i></a>
                        <a className="btn btn-outline-orange-dark btn-square" href="" target='_blank'><i className="fab fa-youtube"></i></a>
                    </div>
                </div>
                
            </div>
        </div>
        <div className="container wow fadeIn" data-wow-delay="0.1s">
            <div className="copyright">
                <div className="row">
                  
                </div>
            </div>
        </div>
    </div>
    </div>
  )
}

export default Footer