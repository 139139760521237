import React from 'react'
import { useNavigate } from 'react-router-dom';

const OurServices = () => {
    
    const navigate = useNavigate();
  const service = () => navigate('/service');
    return (
        <div>
            <div className="container-fluid bg-light py-5" id='services'>
                <div className="container py-5">
                    <div className="row g-5 align-items-center">
                        <div className="col-lg-5 wow fadeIn" data-wow-delay="0.1s">
                            <div className="btn btn-sm border rounded-pill text-orange px-3 mb-3">Our Services</div>
                            <h1 className="mb-4">Elevate Your Vision with Fusion Force: Where Innovation Meets Excellence</h1>
                        </div>
                        <div className="col-lg-7">
                            <div className="row g-4">
                                <div className="col-md-6">
                                    <div className="row g-4">
                                        <div className="col-12 wow fadeIn" data-wow-delay="0.1s">
                                            <div className="service-item d-flex flex-column justify-content-center text-center rounded">
                                                <div className="service-icon btn-square">
                                                <i className="fa fa-cogs fa-2x"></i>
                                                </div>
                                                <h5 className="service-heading mb-3">CUSTOM DEVELOPMENT</h5>
                                                <p className=''>Our custom software development services are tailored to address your unique
                                                    business needs, providing you with the tools to thrive in today's dynamic
                                                    digital landscape. Explore the wide range of services we offer to elevate
                                                    your business.</p>

                                                <a className="btn px-3 mt-auto mx-auto" onClick={service}>Read More</a>
                                            </div>
                                        </div>
                                        <div className="col-12 wow fadeIn" data-wow-delay="0.5s">
                                            <div className="service-item d-flex flex-column justify-content-center text-center rounded">
                                                <div className="service-icon btn-square">
                                                <i className="fa fa-power-off fa-2x"></i>
                                                </div>
                                                <h5 className="service-heading mb-3">BUSINESS SOLUTIONS</h5>
                                                <p>Fusion Force specializes in streamlining your business processes through custom solutions. We analyze your workflows, identify bottlenecks, and design applications that automate tasks, reduce errors, and improve overall efficiency.</p>
                                                <a className="btn px-3 mt-auto mx-auto" onClick={service}>Read More</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 pt-md-4">
                                    <div className="row g-4">
                                        <div className="col-12 wow fadeIn" data-wow-delay="0.3s">
                                            <div className="service-item d-flex flex-column justify-content-center text-center rounded">
                                                <div className="service-icon btn-square">
                                                <i className="fa fa-cloud fa-2x"></i>
                                                </div>
                                                <h5 className="service-heading mb-3">CLOUD SOLUTION</h5>
                                                <p>Fusion Force empowers your organization to soar to new heights in the modern digital landscape through cloud solutions.</p>
                                                <a className="btn px-3 mt-auto mx-auto" onClick={service}>Read More</a>
                                            </div>
                                        </div>
                                        <div className="col-12 wow fadeIn" data-wow-delay="0.7s">
                                            <div className="service-item d-flex flex-column justify-content-center text-center rounded">
                                                <div className="service-icon btn-square">
                                                <i className="fas fa-briefcase fa-2x"></i>
                                                </div>
                                                <h5 className="service-heading mb-3">BUSINESS CONSULTING</h5>
                                                <p>Fusion Force enables your company to rise to high altitudes by providing high skilled resources and staff whenever and wherever needed.</p>
                                                <a className="btn px-3 mt-auto mx-auto" onClick={service}>Read More</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OurServices