import React from 'react'

const WhyChooseUs = () => {
    return (
        <div>
            <div className="container-fluid bg-dark feature-dot py-5 ">
                <div className="container py-5">
                    <div className="row">
                        <div className='col-lg-5' style={{ paddingTop: "150px" }}>
                            
                        </div>
                        <div className="col-lg-7 align-self-center card1 p-md-5 wow fadeIn" data-wow-delay="0.3s">
                            <div className="btn btn-sm border rounded-pill text-orange px-3 my-3">Why Choose Us</div>
                            <h1 className="text-white mb-4">Welcome to Fusion Force: Your Trusted IT Partner</h1>
                            <p className="text-light mb-4 text-justify">At Fusion Force, we are more than just an IT service provider; we are your dedicated technology partner. With a passion for innovation and a commitment to excellence, we offer a comprehensive suite of IT solutions designed to empower your business and drive success.</p>
                            <div className="d-flex align-items-center text-white mb-3">
                                <div className="btn-sm-square bg-white text-orange rounded-circle me-3">
                                    <i className="fa fa-check px-2"></i>
                                </div>
                                <span className='text-justify'>Unparalleled Resources: Our team comprises some of the best minds in the industry, ensuring that you receive the highest level of expertise</span>
                            </div>
                            <div className="d-flex align-items-center text-white mb-3">
                                <div className="btn-sm-square bg-white text-orange rounded-circle me-3">
                                    <i className="fa fa-check px-2"></i>
                                </div>
                                <span className='text-justify'>Innovation: We remain at the forefront of technological trends and innovations, oﬀering insights that keep your business ahead of the curve.</span>
                            </div>
                            <div className="d-flex align-items-center text-white mb-3">
                                <div className="btn-sm-square bg-white text-orange rounded-circle me-3">
                                    <i className="fa fa-check px-2"></i>
                                </div>
                                <span className='text-justify'>Client-Centric Focus: Your success is our primary concern. We work closely with you to develop strategies that align with your unique business objectives</span>
                            </div>
                            <div className="d-flex align-items-center text-white mb-3">
                                <div className="btn-sm-square bg-white text-orange rounded-circle me-3">
                                    <i className="fa fa-check px-2"></i>
                                </div>
                                <span className='text-justify'>Proven Track Record: Our history of successful projects and satisfied clients speaks volumes about our capabilities</span>
                            </div>
                        </div>

                        {/* <div className="row g-4 pt-3">
                        <div className="col-sm-6">
                            <div className="d-flex rounded p-3" style={{background: "rgba(256, 256, 256, 0.1)"}}>
                                <i className="fa fa-users fa-3x text-white"></i>
                                <div className="ms-3">
                                    <h2 className="text-white mb-0" data-toggle="counter-up">9999</h2>
                                    <p className="text-white mb-0">Happy Clients</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="d-flex rounded p-3" style={{background: "rgba(256, 256, 256, 0.1)"}}>
                                <i className="fa fa-check fa-3x text-white"></i>
                                <div className="ms-3">
                                    <h2 className="text-white mb-0" data-toggle="counter-up">9999</h2>
                                    <p className="text-white mb-0">Project Complete</p>
                                </div>
                            </div>
                        </div>
                    </div> */}

                    </div>
                </div>
            </div>
        </div>

    )
}

export default WhyChooseUs